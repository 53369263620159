<!-- Pricing -->
<ng-container *ngFor="let capability of account.capabilities">
  <ng-container
    *ngIf="capability.status !== 'disabled' && (capability.processing_details || capability.pricing_template)"
  >
    <div class="my-4 flex w-full flex-row items-center">
      <mat-icon
        class="text-tilled-primary icon-size-8"
        [svgIcon]="
          capability.product_code?.payment_method_type === 'card'
            ? 'heroicons_solid:credit-card'
            : 'heroicons_solid:library'
        "
      ></mat-icon>
      <tilled-heading-h4 class="mx-2">
        {{ capability.product_code?.payment_method_type === 'card' ? 'Card' : 'Direct Debit' }} Processing Details
      </tilled-heading-h4>
      <mat-divider class="light-divider sm:flex-grow"></mat-divider>
    </div>

    <div class="grid w-full grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <!-- General -->
      <fuse-card *ngIf="capability.processing_details" class="tilled-card-styling h-fit overflow-hidden" #fuseCard>
        <div class="w-full">
          <div class="w-full">
            <tilled-heading-h4>General</tilled-heading-h4>
          </div>
          <mat-divider class="card-title-divider"></mat-divider>
          <ng-container>
            <div class="w-full">
              <tilled-card-row labelText="Status" [valueText]="capability.status | titlecase"></tilled-card-row>
              <tilled-card-row
                labelText="Processing Currency"
                [valueText]="capability.processing_details.processing_currency | uppercase"
              ></tilled-card-row>
              <tilled-card-row
                labelText="Settlement Currency"
                [valueText]="capability.processing_details.settlement_currency | uppercase"
              ></tilled-card-row>

              <ng-container *ngIf="capability.processing_details.destination_bank_account?.type === 'ach'">
                <tilled-card-row
                  labelText="Bank Account Number"
                  [valueText]="'*******' + capability.processing_details.destination_bank_account.last3"
                ></tilled-card-row>
                <tilled-card-row
                  labelText="Bank Routing Number"
                  [valueText]="capability.processing_details.destination_bank_account.routing_number"
                ></tilled-card-row>
              </ng-container>
              <ng-container *ngIf="capability.processing_details.destination_bank_account?.type === 'eft'">
                <tilled-card-row
                  labelText="Bank Account Number"
                  [valueText]="'*******' + capability.processing_details.destination_bank_account.last3"
                ></tilled-card-row>
                <tilled-card-row
                  labelText="Bank Institution ID"
                  [valueText]="capability.processing_details.destination_bank_account.institution_id"
                ></tilled-card-row>
                <tilled-card-row
                  labelText="Bank Transit Number"
                  [valueText]="capability.processing_details.destination_bank_account.transit_number"
                ></tilled-card-row>
              </ng-container>

              <tilled-card-row
                labelText="MCC Description"
                [valueText]="capability.processing_details.mcc_description"
              ></tilled-card-row>
              <tilled-card-row
                labelText="Statement Descriptor"
                [valueText]="capability.processing_details.default_statement_descriptor"
              ></tilled-card-row>
              <tilled-card-row
                labelText="Funding Hold"
                [valueText]="capability.processing_details.funding_hold ? 'Yes' : 'No'"
              ></tilled-card-row>
            </div>
          </ng-container>
        </div>
      </fuse-card>

      <!-- Billing & Payout Schedule -->
      <fuse-card *ngIf="capability.processing_details" class="tilled-card-styling h-fit overflow-hidden" #fuseCard>
        <div class="w-full">
          <div class="w-full">
            <tilled-heading-h4 class="inline-block">Billing & Payout Schedule</tilled-heading-h4>
            <div class="float-right inline-block" *ngIf="isCapabilityEditable(capability)">
              <button data-test-id="edit-billing-payout-schedules" (click)="editBillingPayoutSchedules(capability)">
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
              </button>
            </div>
          </div>
          <mat-divider class="card-title-divider"></mat-divider>
          <ng-container>
            <div class="w-full">
              <tilled-card-row labelText="Settlement Type" valueText="Net"></tilled-card-row>
              <tilled-card-row
                labelText="Billing Frequency"
                [valueText]="capability.processing_details.billing_frequency"
                labelInfoTooltip="The billing frequency refers to how often a merchant is billed for fees. While daily billing is standard, some merchants may prefer weekly or monthly."
              ></tilled-card-row>
              <tilled-card-row
                *ngIf="capability.processing_details.billing_interval_unit === 'week'"
                labelText="Billing Anchor Day"
                [valueText]="
                  !capability.processing_details.billing_anchor
                    ? '-'
                    : (capability.processing_details.billing_anchor | date : 'EEEE')
                "
                labelInfoTooltip="The billing anchor day refers to the day of the week or month on which fees are billed. If fee collection fails due to a low account balance, the billing job will retry until the collection succeeds. As a result, the actual billing day may be different from the scheduled billing day."
              ></tilled-card-row>
              <tilled-card-row
                *ngIf="capability.processing_details.billing_interval_unit === 'month'"
                labelText="Billing Anchor Day"
                [valueText]="
                  !capability.processing_details.billing_anchor
                    ? '-'
                    : textForMonthlyAnchorDate(capability.processing_details.billing_anchor)
                "
                labelInfoTooltip="The billing anchor day refers to the day of the week or month on which fees are billed. If fee collection fails due to a low account balance, the billing job will retry until the collection succeeds. As a result, the actual billing day may be different from the scheduled billing day."
              ></tilled-card-row>
              <tilled-card-row
                labelText="Payout Frequency"
                [valueText]="capability.processing_details.payout_frequency"
                labelInfoTooltip="The merchant's payout frequency refers to how often the merchant receives funds from their settled transactions."
              ></tilled-card-row>
              <tilled-card-row
                *ngIf="capability.processing_details.payout_interval_unit === 'week'"
                labelText="Payout Anchor Day"
                [valueText]="
                  !capability.processing_details.payout_anchor
                    ? '-'
                    : (capability.processing_details.payout_anchor | date : 'EEEE')
                "
                labelInfoTooltip="The payout anchor day refers to the day of the week or month on which funds are disbursed to the merchant's bank account."
              ></tilled-card-row>
              <tilled-card-row
                *ngIf="capability.processing_details.payout_interval_unit === 'month'"
                labelText="Payout Anchor Day"
                [valueText]="
                  !capability.processing_details.payout_anchor
                    ? '-'
                    : textForMonthlyAnchorDate(capability.processing_details.payout_anchor)
                "
                labelInfoTooltip="The payout anchor day refers to the day of the week or month on which funds are disbursed to the merchant's bank account."
              ></tilled-card-row>
              <tilled-card-row
                labelText="Payout Speed"
                [valueText]="capability.processing_details.payout_speed"
                labelInfoTooltip="The payout speed refers to the days between transaction settlement and payout creation. 'T' refers to transaction settlement and 'X' refers to the number of days before funds are available for payout."
              ></tilled-card-row>
            </div>
          </ng-container>
        </div>
      </fuse-card>

      <!-- Pricing -->
      <!-- Pricing.Card -->
      <fuse-card
        *ngIf="capability.pricing_template?.card as template"
        class="tilled-card-styling h-fit overflow-hidden"
        #fuseCard
      >
        <div class="w-full">
          <div class="w-full">
            <tilled-heading-h4>Pricing</tilled-heading-h4>
          </div>
          <mat-divider class="card-title-divider"></mat-divider>
          <ng-container>
            <div class="w-full">
              <tilled-card-row
                labelText="Currency"
                [valueText]="capability.pricing_template.currency | uppercase"
              ></tilled-card-row>

              <tilled-card-row
                labelText="Fee Type"
                [valueText]="capability.pricing_template.card.transaction_fee_type | snakeCaseSplit | titlecase"
              ></tilled-card-row>

              <tilled-card-row
                [labelText]="
                  capability.pricing_template.card.transaction_fee_type === 'interchange'
                    ? 'Visa IC+ Markup'
                    : 'Visa Discount Fee'
                "
                [valueText]="getVisaDiscountFee(capability.pricing_template) / 100 | percent : '1.2-2'"
              ></tilled-card-row>

              <tilled-card-row
                [labelText]="
                  capability.pricing_template.card.transaction_fee_type === 'interchange'
                    ? 'Amex IC+ Markup'
                    : 'Amex Discount Fee'
                "
                [valueText]="getAmexDiscountFee(capability.pricing_template) / 100 | percent : '1.2-2'"
              ></tilled-card-row>

              <tilled-card-row
                [labelText]="
                  capability.pricing_template.card.transaction_fee_type === 'interchange'
                    ? 'Transaction Fee Markup'
                    : 'Transaction Fee'
                "
                [valueText]="
                  capability.pricing_template.card.transaction_fee
                    | minorUnitsToCurrency : capability.pricing_template.currency
                "
              ></tilled-card-row>
              <tilled-card-row
                labelText="Chargeback Fee"
                [valueText]="
                  capability.pricing_template.card.chargeback_fee
                    | minorUnitsToCurrency : capability.pricing_template.currency
                "
              ></tilled-card-row>
              <tilled-card-row
                labelText="Retrieval Fee"
                [valueText]="
                  capability.pricing_template.card.retrieval_fee
                    | minorUnitsToCurrency : capability.pricing_template.currency
                "
              ></tilled-card-row>
              <tilled-card-row
                labelText="Reversal Fee"
                [valueText]="
                  capability.pricing_template.card.reversal_fee
                    | minorUnitsToCurrency : capability.pricing_template.currency
                "
              ></tilled-card-row>
              <tilled-card-row
                labelText="Monthly Account Fee"
                [valueText]="
                  capability.pricing_template.account_monthly_fee
                    | minorUnitsToCurrency : capability.pricing_template.currency
                "
              ></tilled-card-row>
              <tilled-card-row
                labelText="Monthly Minimum Fee"
                [valueText]="
                  capability.pricing_template.account_monthly_minimum_fee
                    | minorUnitsToCurrency : capability.pricing_template.currency
                "
              ></tilled-card-row>
            </div>
          </ng-container>
        </div>
      </fuse-card>

      <!-- Pricing.ACH Debit -->
      <fuse-card
        *ngIf="capability.pricing_template?.ach_debit as template"
        class="tilled-card-styling h-fit overflow-hidden"
        #fuseCard
      >
        <div class="w-full">
          <div class="w-full">
            <tilled-heading-h4>Pricing</tilled-heading-h4>
          </div>
          <mat-divider class="card-title-divider"></mat-divider>
          <ng-container>
            <div class="w-full">
              <tilled-card-row
                labelText="Currency"
                [valueText]="capability.pricing_template.currency | uppercase"
              ></tilled-card-row>

              <tilled-card-row
                labelText="Fee Type"
                [valueText]="capability.pricing_template.ach_debit.transaction_fee_type | snakeCaseSplit | titlecase"
              ></tilled-card-row>

              <tilled-card-row
                labelText="Transaction Fee"
                [valueText]="
                  capability.pricing_template.ach_debit.transaction_fee
                    | minorUnitsToCurrency : capability.pricing_template.currency
                "
              ></tilled-card-row>
              <tilled-card-row
                labelText="Return Fee"
                [valueText]="
                  capability.pricing_template.ach_debit.return_fee
                    | minorUnitsToCurrency : capability.pricing_template.currency
                "
              ></tilled-card-row>

              <tilled-card-row
                labelText="Monthly Account Fee"
                [valueText]="
                  capability.pricing_template.account_monthly_fee
                    | minorUnitsToCurrency : capability.pricing_template.currency
                "
              ></tilled-card-row>
              <tilled-card-row
                labelText="Monthly Minimum Fee"
                [valueText]="
                  capability.pricing_template.account_monthly_minimum_fee
                    | minorUnitsToCurrency : capability.pricing_template.currency
                "
              ></tilled-card-row>
            </div>
          </ng-container>
        </div>
      </fuse-card>
    </div>
  </ng-container>
</ng-container>
