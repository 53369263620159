import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { ReportRunStatusPipe } from 'app/core/pipes/download-status.pipe';
import { ReportRunsAppService } from 'app/core/services/report-runs.app.service';
import { Observable, Subscription, interval } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { InternalReportRun } from '../../../../projects/tilled-api-client/src/model/internalReportRun';
import { TilledChipConfig } from '../tilled-chip/tilled-chip.component';
import { Column } from '../tilled-table/decorators/column';

@Component({
  selector: 'app-report-run-list',
  templateUrl: './report-run-list.component.html',
  styleUrls: ['./report-run-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ReportRunListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() accountId: string;
  @Input() type: InternalReportRun.TypeEnum;
  @Input() isMerchant: boolean;

  readonly paymentsSummary1: InternalReportRun.TypeEnum = InternalReportRun.TypeEnum.PAYMENTS_SUMMARY_1;
  readonly payoutsSummary2: InternalReportRun.TypeEnum = InternalReportRun.TypeEnum.PAYOUTS_SUMMARY_2;
  readonly feesSummary1: InternalReportRun.TypeEnum = InternalReportRun.TypeEnum.FEES_SUMMARY_1;
  readonly processingSummary1: InternalReportRun.TypeEnum = InternalReportRun.TypeEnum.PROCESSING_SUMMARY_1;
  readonly disputesSummary1: InternalReportRun.TypeEnum = InternalReportRun.TypeEnum.DISPUTES_SUMMARY_1;

  public pageIndex = 0;
  public pageSize = 5;
  public reportRuns$: Observable<InternalReportRun[]>;
  public reportRunsCount$: Observable<number>;
  public viewModels$: Observable<ReportRunViewModel[]>;
  public timeInterval: Subscription;
  public hideColumns: number[] = [];

  constructor(
    private _reportRunAppService: ReportRunsAppService,
    private _reportRunStatusPipe: ReportRunStatusPipe,
    private _dateFormatPipe: DateFormatPipe,
  ) {}

  ngOnInit(): void {
    if (this.type === InternalReportRun.TypeEnum.PAYMENTS_SUMMARY_1) {
      this.reportRunsCount$ = this._reportRunAppService.paymentReportRunsCount$;
      this.reportRuns$ = this._reportRunAppService.paymentReportRuns$;
    } else if (this.type === InternalReportRun.TypeEnum.PAYOUTS_SUMMARY_2) {
      this.reportRunsCount$ = this._reportRunAppService.payoutReportRunsCount$;
      this.reportRuns$ = this._reportRunAppService.payoutReportRuns$;
    } else if (this.type === InternalReportRun.TypeEnum.FEES_SUMMARY_1) {
      this.reportRunsCount$ = this._reportRunAppService.feeReportRunsCount$;
      this.reportRuns$ = this._reportRunAppService.feeReportRuns$;
    } else if (this.type === InternalReportRun.TypeEnum.PROCESSING_SUMMARY_1) {
      this.reportRunsCount$ = this._reportRunAppService.processingReportRunsCount$;
      this.reportRuns$ = this._reportRunAppService.processingReportRuns$;
    } else if (this.type === InternalReportRun.TypeEnum.DISPUTES_SUMMARY_1) {
      this.reportRunsCount$ = this._reportRunAppService.disputeReportRunsCount$;
      this.reportRuns$ = this._reportRunAppService.disputeReportRuns$;
    }

    this.viewModels$ = this.reportRuns$.pipe(map((reportRuns) => this.getViewModelsFromReportRuns(reportRuns)));

    if (this.type === InternalReportRun.TypeEnum.PAYMENTS_SUMMARY_1) {
      this.getPaymentsReportRuns(this.pageSize, this.pageIndex);
    } else if (this.type === InternalReportRun.TypeEnum.PAYOUTS_SUMMARY_2) {
      this.getPayoutsReportRuns(this.pageSize, this.pageIndex);
    } else if (this.type === InternalReportRun.TypeEnum.FEES_SUMMARY_1) {
      this.getFeesReportRuns(this.pageSize, this.pageIndex);
    } else if (this.type === InternalReportRun.TypeEnum.PROCESSING_SUMMARY_1) {
      this.getProcessingReportRuns(this.pageSize, this.pageIndex);
    } else if (this.type === InternalReportRun.TypeEnum.DISPUTES_SUMMARY_1) {
      this.getDisputeReportRuns(this.pageSize, this.pageIndex);
    }

    if (this.isMerchant) {
      this.hideColumns.push(2);
    }
  }

  ngOnDestroy(): void {
    this.timeInterval.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.timeInterval = interval(10000)
      .pipe(startWith(0))
      .subscribe((res) => {
        if (this._reportRunAppService.paymentReportRunHasPending) {
          this.getPaymentsReportRuns(this.pageSize, this.pageIndex);
        } else if (this._reportRunAppService.payoutReportRunHasPending) {
          this.getPayoutsReportRuns(this.pageSize, this.pageIndex);
        } else if (this._reportRunAppService.feeReportRunHasPending) {
          this.getFeesReportRuns(this.pageSize, this.pageIndex);
        } else if (this._reportRunAppService.processingReportRunHasPending) {
          this.getProcessingReportRuns(this.pageSize, this.pageIndex);
        } else if (this._reportRunAppService.disputeReportRunHasPending) {
          this.getDisputeReportRuns(this.pageSize, this.pageIndex);
        }
      });
  }

  getViewModelsFromReportRuns(reportRuns: InternalReportRun[]): ReportRunViewModel[] {
    const viewModels: ReportRunViewModel[] = [];
    if (!reportRuns || reportRuns.length === 0) {
      const temp: ReportRunViewModel = new ReportRunViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    for (const reportRun of reportRuns) {
      const localStartAt = new Date(reportRun.parameters.start_at);
      const localEndAt = new Date(reportRun.parameters.end_at);
      const localRangeString =
        localStartAt.toLocaleDateString('en-us') + ' - ' + localEndAt.toLocaleDateString('en-us');

      const reportName = reportRun.result?.filename ? (reportRun.result.filename as string).split('/').pop() : '';
      const temp: ReportRunViewModel = new ReportRunViewModel();
      temp.dates = localRangeString;
      temp.file_name = reportName;
      temp.merchant_name = reportRun.connected_account_name;
      temp.created_at = this._dateFormatPipe.transform(reportRun.created_at);
      temp.file_id = reportRun.result?.id ? reportRun.result.id : '';
      temp.account_id = this.accountId;
      temp.status = reportRun.status;
      temp.chipConfig = this._reportRunStatusPipe.transform(reportRun, false);
      viewModels.push(temp);
    }
    return viewModels;
  }

  getPaymentsReportRuns = (size: number, index: number): void => {
    this.pageSize = size;
    this.pageIndex = index;
    this._reportRunAppService.getPaymentReportRuns(this.accountId, this.pageSize * this.pageIndex, this.pageSize);
  };

  getPayoutsReportRuns = (size: number, index: number): void => {
    this.pageSize = size;
    this.pageIndex = index;
    this._reportRunAppService.getPayoutReportRuns(this.accountId, this.pageSize * this.pageIndex, this.pageSize);
  };

  getFeesReportRuns = (size: number, index: number): void => {
    this.pageSize = size;
    this.pageIndex = index;
    this._reportRunAppService.getFeeReportRuns(this.accountId, this.pageSize * this.pageIndex, this.pageSize);
  };

  getProcessingReportRuns = (size: number, index: number): void => {
    this.pageSize = size;
    this.pageIndex = index;
    this._reportRunAppService.getProcessingReportRuns(this.accountId, this.pageSize * this.pageIndex, this.pageSize);
  };

  getDisputeReportRuns = (size: number, index: number): void => {
    this.pageSize = size;
    this.pageIndex = index;
    this._reportRunAppService.getDisputeReportRuns(this.accountId, this.pageSize * this.pageIndex, this.pageSize);
  };
}

export class ReportRunViewModel {
  @Column({
    order: 0,
    name: 'Download Date',
  })
  created_at: string;

  @Column({
    order: 1,
    name: 'Report Name',
  })
  file_name: string;

  @Column({
    order: 2,
    name: 'Merchant',
  })
  merchant_name: string;

  @Column({
    order: 3,
    name: 'Date Range',
  })
  dates: string;

  @Column({
    order: 4,
    name: 'Status',
    isChip: true,
  })
  status: string;

  @Column({
    order: 5,
    isDownload: true,
  })
  file_id: string;

  id: string;

  type: InternalReportRun.TypeEnum;

  account_id: string;

  // non display, needed if trying to display status chip
  chipConfig: TilledChipConfig;
}
