/* eslint-disable @typescript-eslint/member-ordering */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { StatusCodes } from 'http-status-codes';
import jwt_decode from 'jwt-decode';
import * as _ from 'lodash';
import { BehaviorSubject, catchError, lastValueFrom, map, Observable, of, share, switchMap, throwError } from 'rxjs';
import {
  AccountsService,
  ApiKey,
  AuthLinkLoginDto,
  ForgotPasswordParams,
  ForgotPasswordRequestParams,
  GetUserRequestParams,
  InternalAccount,
  InternalGetAccountRequestParams,
  InternalRedeemAuthLinkRequestParams,
  InternalService,
  LoginDto,
  LoginRequestParams,
  RefreshAccessTokenRequestParams,
  RegisterParams,
  RegisterRequestParams,
  ResetPasswordRequestParams,
  User,
  UserResetPasswordParams,
  UsersService,
} from '../../../../projects/tilled-api-client/src';
import { AUTH_LOGIN_ROUTE } from '../constants';
import { AppUser, JwtTokenData } from '../data/auth-types';

const TOKEN_STORAGE_KEY = 'tilled-token'; // 'tilled-access-token'
const REFRESH_TOKEN_STORAGE_KEY = 'tilled-refresh-token';

declare let pendo: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _decodedAccessToken?: JwtTokenData;
  private _account?: InternalAccount;
  private _appUser: BehaviorSubject<AppUser> = new BehaviorSubject<AppUser>(null);
  public isMerchantAccount$: Observable<boolean>;

  constructor(
    private _usersService: UsersService,
    private _accountService: AccountsService,
    private _internalService: InternalService,
    private _router: Router,
  ) {
    // Set the observable based on the user behavior subject.
    this.isMerchantAccount$ = this.user$.pipe(
      map((user) => user?.role === User.RoleEnum.MERCHANT_ADMIN || user?.role === User.RoleEnum.MERCHANT_OWNER),
    );
    // Initialize _user and _decodedToken
    this.handleNewAccessToken(AuthService.getAccessToken());
  }

  // Get the user as observable
  get user$(): Observable<AppUser> | null {
    if (!this._appUser) {
      this.reset();
    }
    return this._appUser.asObservable();
  }

  // Get the user value as a static var.
  get user(): AppUser | null {
    return this._appUser.getValue();
  }

  private setUser(token: JwtTokenData | null) {
    // Only place we set the user.
    this._appUser.next(token);

    if (token) {
      Sentry.setUser({
        id: token.id,
        //email: user.email,
        //account_id: user.account_id,
      });
    } else {
      Sentry.setUser(null);
    }
  }

  /**
   * Retrieve the Tilled API access_token out of session or local storage.
   * @returns
   */
  public static getAccessToken(): string {
    return sessionStorage.getItem(TOKEN_STORAGE_KEY) ?? localStorage.getItem(TOKEN_STORAGE_KEY);
  }

  /**
   * Retrieve the Tilled API refresh_token out of session or local storage.
   * @returns
   */
  public static getRefreshToken(): string {
    return sessionStorage.getItem(REFRESH_TOKEN_STORAGE_KEY) ?? localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  }

  // If we're storing the refresh token in local storage then rememberMe was true
  private isRememberMe(): boolean {
    return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY) != null;
  }

  public redeemAuthLink(id: string): Observable<AuthLinkLoginDto> {
    const params: InternalRedeemAuthLinkRequestParams = {
      id: id,
    };

    return this._internalService.internalRedeemAuthLink(params).pipe(
      switchMap((result) => {
        if (result.credentials) {
          this.reset();
          this.setRefreshToken(result.credentials.refresh_token, true);
          this.setAccessToken(result.credentials.token, true);
        }
        return of(result);
      }),
      catchError((err) => {
        return throwError(err);
      }),
      share(),
    );
  }

  login(body: { email: string; password: string }, rememberMe = false): Observable<void> {
    const requestParams: LoginRequestParams = {
      loginParams: body,
    };
    return this._usersService.login(requestParams).pipe(
      map((result) => {
        this.reset();
        this.setRefreshToken(result.refresh_token, rememberMe);
        this.setAccessToken(result.token, rememberMe);
      }),
    );
  }

  /**
   * This is used for impersonation primarily. We don't use refresh tokens here.
   * @param token
   * @param rememberMe
   * @returns
   */
  loginWithToken(token: string, rememberMe = false): Observable<void> {
    // Attempt to decode the token first, then make an API call to fetch the user
    // if that passes, then the token is valid.
    return this.isTokenValid(token).pipe(
      map((isValid) => {
        // Reset no matter what?
        this.reset();

        if (isValid) {
          this.setAccessToken(token, rememberMe);
        } else {
          throw new HttpErrorResponse({
            error: new Error('Invalid token'),
            status: StatusCodes.UNAUTHORIZED,
          });
        }
      }),
    );
  }

  refreshAccessToken(): Observable<LoginDto> {
    const requestParams: RefreshAccessTokenRequestParams = {
      accessTokenRefreshParams: {
        refresh_token: AuthService.getRefreshToken(),
      },
    };
    return this._usersService.refreshAccessToken(requestParams).pipe(
      switchMap((response: LoginDto) => {
        const rememberMe = this.isRememberMe();
        this.reset();
        this.setRefreshToken(response.refresh_token, rememberMe);
        this.setAccessToken(response.token, rememberMe);
        return of(response);
      }),
      catchError((err) => {
        this.reset();
        return throwError(err);
      }),
    );
  }

  // Creates user and 'partner' account
  register(body: RegisterParams): Observable<void> {
    const requestParams: RegisterRequestParams = {
      registerParams: body,
    };
    return this._usersService.register(requestParams).pipe(
      map((result) => {
        this.reset();
        this.setAccessToken(result.token, false);
        // TODO: Possibly include refresh_token during registration.
        //this.setRefreshToken(result.refresh_token, false);
      }),
    );
  }

  forgotPassword(body: ForgotPasswordParams): Observable<void> {
    const requestParams: ForgotPasswordRequestParams = {
      forgotPasswordParams: body,
    };
    return this._usersService.forgotPassword(requestParams);
  }

  resetPassword(body: UserResetPasswordParams): Observable<void> {
    const requestParams: ResetPasswordRequestParams = {
      userResetPasswordParams: body,
    };
    return this._usersService.resetPassword(requestParams);
  }

  logout(redirect = false): void {
    if (!this.isRefreshTokenExpired()) {
      this._usersService.logout().subscribe(
        (res) => {
          this.reset();
        },
        (error) => {
          this.reset();
        },
      );
    } else {
      this.reset();
    }

    if (redirect) {
      this._router.navigate(['/' + AUTH_LOGIN_ROUTE]);
    }
  }

  public setAccessToken(token: string, rememberMe: boolean): void {
    if (rememberMe) {
      localStorage.setItem(TOKEN_STORAGE_KEY, token);
    } else {
      sessionStorage.setItem(TOKEN_STORAGE_KEY, token);
    }

    this.handleNewAccessToken(token);
  }

  public setRefreshToken(token: string, rememberMe: boolean): void {
    if (rememberMe) {
      localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, token);
    } else {
      sessionStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, token);
    }
  }

  async getAccount(): Promise<InternalAccount> {
    if (this._account) {
      return this._account;
    } else {
      return this.reloadAccount();
    }
  }

  getUserScopes(): ApiKey.ScopesEnum[] | null {
    return this._decodedAccessToken?.scopes;
  }

  public async reloadAccount(): Promise<InternalAccount> {
    if (!this._appUser) {
      this.logout();
      return null;
    } else {
      const requestParams: InternalGetAccountRequestParams = {
        tilledAccount: this._appUser.value.account_id,
      };
      return lastValueFrom(this._internalService.internalGetAccount(requestParams));
    }
  }

  setAccount(account: InternalAccount) {
    this._account = account;
  }

  private handleNewAccessToken(accessToken: string): void {
    if (accessToken) {
      try {
        this._decodedAccessToken = jwt_decode<JwtTokenData>(accessToken);
        if (this._decodedAccessToken) {
          const tmp = _.cloneDeep(this._decodedAccessToken);
          delete tmp.iat;
          delete tmp.jti;
          delete tmp.exp;
          this.setUser(tmp);
          this.reloadAccount().then((account) => {
            this._account = account;
            this.configurePendo(tmp, account);
          });
        } else {
          this.setUser(null);
        }
      } catch (error) {
        this.reset();
      }
    } else {
      this._decodedAccessToken = null;
      this.setUser(null);
    }
  }

  configurePendo(user: JwtTokenData, account: InternalAccount) {
    let visitorId = user.id;
    if (user.impersonated_by) {
      // Pendo said adding a metadata tag isn't a great idea because it can be overwritten
      // and the analytics tool pulls the most recent data associated with that visitor.
      // Instead, they agreed that manipulating the visitor id was the best bet for
      // determining whether a user was being impersonated
      visitorId = `${user.id}_impersonated_by_${user.impersonated_by}`;
    }
    pendo.initialize({
      visitor: {
        id: visitorId,
        email: user.email,
        full_name: user.name,
        role: user.role,
      },
      account: {
        id: user.account_id,
        name: account.name,
      },
    });
  }

  isTokenExpired(): boolean {
    const expiryTime: number = this._decodedAccessToken?.exp;
    if (expiryTime) {
      return Date.now() >= expiryTime * 1000;
    } else {
      return false; // Should we return true if there is no token at all?
    }
  }

  isRefreshTokenExpired(): boolean {
    const refreshToken = AuthService.getRefreshToken();
    if (refreshToken) {
      const decodedRefreshToken = jwt_decode<JwtTokenData>(refreshToken);
      const expiryTime: number = decodedRefreshToken?.exp;
      if (expiryTime) {
        return Date.now() >= expiryTime * 1000;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  isScopeAble(scope: ApiKey.ScopesEnum) {
    if (this._decodedAccessToken === null || this._decodedAccessToken.scopes === null) {
      return false;
    } else {
      return this._decodedAccessToken.scopes.includes('*') || this._decodedAccessToken.scopes.includes(scope);
    }
  }

  isImpersonated(): boolean {
    return this._decodedAccessToken?.impersonated || false;
  }

  /**
   * If the feature-toggle is valid for this user then it will return true. Else false.
   * @param feature e.g. 'cool.new.feature'
   * @returns
   */
  isFeatureEnabled(feature: string): boolean {
    if (feature == null || this._decodedAccessToken === null || this._decodedAccessToken.features == null) {
      return false;
    } else {
      return this._decodedAccessToken.features[feature];
    }
  }

  private reset(): void {
    this._decodedAccessToken = null;
    this.setUser(null);
    this._account = null;
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    sessionStorage.removeItem(TOKEN_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
    sessionStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
    // this.ability.update([]); // Remove all abilities
  }

  /**
   * When we pass `?token=` as a query parameter, we attempt to validate it first by:
   * 1) Decoding it
   * 2) Making an API call to `/v1/users/:id` (this might not work if scope permissions are missing for `users:read`)
   * 2b) Perhaps we need a `/v1/auth/token` or `/v1/auth/validate` endpoint...
   * @param token jwt
   */
  isTokenValid(token: string): Observable<boolean> {
    try {
      const decodedToken = jwt_decode<JwtTokenData>(token);
      if (decodedToken) {
        // Override the default 'accessToken' configuration
        this._usersService.configuration.accessToken = token;
        const requestParams: GetUserRequestParams = {
          tilledAccount: decodedToken.account_id,
          id: decodedToken.id,
        };
        return this._usersService.getUser(requestParams).pipe(
          map((user) => {
            return user != null;
          }),
        );
      }
    } catch (error) {
      return of(false);
    }
  }

  isMerchantUser(): boolean {
    if (this._appUser && !this.isRefreshTokenExpired()) {
      let result =
        this._appUser.value.role === User.RoleEnum.MERCHANT_ADMIN ||
        this._appUser.value.role === User.RoleEnum.MERCHANT_OWNER;
      return result;
    }
  }

  isAdminUser(): boolean {
    if (this._appUser && !this.isRefreshTokenExpired()) {
      return this._appUser.value.role === User.RoleEnum.ADMIN || this._appUser.value.role === User.RoleEnum.OWNER;
    }
  }

  isISVViewOnlyUser(): boolean {
    if (this._appUser && !this.isRefreshTokenExpired()) {
      return this._appUser.value.role === User.RoleEnum.VIEW_ONLY;
    }
  }
}
