import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import {
  CreateReportRunRequestParams,
  InternalService,
  ListReportRunsRequestParams,
  ReportRun,
  ReportRunCreateParams,
  ReportRunsService,
} from '../../../../projects/tilled-api-client/src';
import { InternalReportRun } from '../../../../projects/tilled-api-client/src/model/internalReportRun';
import { DEFAULT_PAGE_LIMIT } from '../constants';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class ReportRunsAppService {
  // should this app service no longer be keeping tracking of multiple observables.
  // it gets confusing when there are multiple different 'types' of report runs here.
  // best pattern for multiple types of observables of the same class used in the same component?
  private _paymentReportRuns$ = new ReplaySubject<InternalReportRun[]>();
  private _paymentReportRunsCount$ = new ReplaySubject<number>();
  private _paymentReportRunCreated$ = new Subject<boolean>();
  public paymentReportRunHasPending = true;

  private _payoutReportRuns$ = new ReplaySubject<InternalReportRun[]>();
  private _payoutReportRunsCount$ = new ReplaySubject<number>();
  private _payoutReportRunCreated$ = new Subject<boolean>();
  public payoutReportRunHasPending = true;

  private _feeReportRuns$ = new ReplaySubject<InternalReportRun[]>();
  private _feeReportRunsCount$ = new ReplaySubject<number>();
  private _feeReportRunCreated$ = new Subject<boolean>();
  public feeReportRunHasPending = true;

  private _processingReportRuns$ = new ReplaySubject<InternalReportRun[]>();
  private _processingReportRunsCount$ = new ReplaySubject<number>();
  private _processingReportRunCreated$ = new Subject<boolean>();
  public processingReportRunHasPending = true;

  private _disputeReportRuns$ = new ReplaySubject<InternalReportRun[]>();
  private _disputeReportRunsCount$ = new ReplaySubject<number>();
  private _disputeReportRunCreated$ = new Subject<boolean>();
  public disputeReportRunHasPending = true;

  constructor(
    private _reportRunsService: ReportRunsService,
    private _internalService: InternalService,
    private _alertService: AlertService,
  ) {}

  get paymentReportRuns$(): Observable<InternalReportRun[]> {
    return this._paymentReportRuns$.asObservable();
  }

  get paymentReportRunsCount$(): Observable<number> {
    return this._paymentReportRunsCount$.asObservable();
  }

  get paymentReportRunCreated$(): Observable<boolean> {
    return this._paymentReportRunCreated$.asObservable();
  }

  get payoutReportRuns$(): Observable<InternalReportRun[]> {
    return this._payoutReportRuns$.asObservable();
  }

  get payoutReportRunsCount$(): Observable<number> {
    return this._payoutReportRunsCount$.asObservable();
  }

  get payoutReportRunCreated$(): Observable<boolean> {
    return this._payoutReportRunCreated$.asObservable();
  }

  get feeReportRuns$(): Observable<InternalReportRun[]> {
    return this._feeReportRuns$.asObservable();
  }

  get feeReportRunsCount$(): Observable<number> {
    return this._feeReportRunsCount$.asObservable();
  }

  get feeReportRunCreated$(): Observable<boolean> {
    return this._feeReportRunCreated$.asObservable();
  }

  get processingReportRuns$(): Observable<InternalReportRun[]> {
    return this._processingReportRuns$.asObservable();
  }

  get processingReportRunsCount$(): Observable<number> {
    return this._processingReportRunsCount$.asObservable();
  }

  get processingReportRunCreated$(): Observable<boolean> {
    return this._processingReportRunCreated$.asObservable();
  }

  get disputeReportRuns$(): Observable<InternalReportRun[]> {
    return this._disputeReportRuns$.asObservable();
  }

  get disputeReportRunsCount$(): Observable<number> {
    return this._disputeReportRunsCount$.asObservable();
  }

  get disputeReportRunCreated$(): Observable<boolean> {
    return this._disputeReportRunCreated$.asObservable();
  }

  getPaymentReportRuns(accountId: string, offset = 0, limit = DEFAULT_PAGE_LIMIT): void {
    const params: ListReportRunsRequestParams = {
      tilledAccount: accountId,
      reportRunRetrieveParams: {
        include_expired: false,
      },
      offset: offset,
      limit: limit,
      type: [ReportRun.TypeEnum.PAYMENTS_SUMMARY_1],
    };
    this._internalService
      .internalListReportRuns(params)
      .pipe(
        tap((res) => this._paymentReportRunsCount$.next(res.total)),
        map((res) => res.items),
        shareReplay(1),
      )
      .subscribe({
        next: (reportRuns) => {
          this._paymentReportRuns$.next(reportRuns);
          this.paymentReportRunHasPending = false;
          if (reportRuns.some((reportRun) => reportRun.status === ReportRun.StatusEnum.QUEUED)) {
            this.paymentReportRunHasPending = true;
          }
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not load payment reports',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }

  getPayoutReportRuns(accountId: string, offset = 0, limit = DEFAULT_PAGE_LIMIT): void {
    const params: ListReportRunsRequestParams = {
      tilledAccount: accountId,
      reportRunRetrieveParams: {
        include_expired: false,
      },
      offset: offset,
      limit: limit,
      type: [ReportRun.TypeEnum.PAYOUTS_SUMMARY_2],
    };
    this._internalService
      .internalListReportRuns(params)
      .pipe(
        tap((res) => this._payoutReportRunsCount$.next(res.total)),
        map((res) => res.items),
        shareReplay(1),
      )
      .subscribe({
        next: (reportRuns) => {
          this._payoutReportRuns$.next(reportRuns);
          this.payoutReportRunHasPending = false;
          if (reportRuns.some((reportRun) => reportRun.status === ReportRun.StatusEnum.QUEUED)) {
            this.payoutReportRunHasPending = true;
          }
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not load payout reports',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }

  getFeeReportRuns(accountId: string, offset = 0, limit = DEFAULT_PAGE_LIMIT): void {
    const params: ListReportRunsRequestParams = {
      tilledAccount: accountId,
      reportRunRetrieveParams: {
        include_expired: false,
      },
      offset: offset,
      limit: limit,
      type: [ReportRun.TypeEnum.FEES_SUMMARY_1],
    };
    this._internalService
      .internalListReportRuns(params)
      .pipe(
        tap((res) => this._feeReportRunsCount$.next(res.total)),
        map((res) => res.items),
        shareReplay(1),
      )
      .subscribe({
        next: (reportRuns) => {
          this._feeReportRuns$.next(reportRuns);
          this.feeReportRunHasPending = false;
          if (reportRuns.some((reportRun) => reportRun.status === ReportRun.StatusEnum.QUEUED)) {
            this.feeReportRunHasPending = true;
          }
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not load merchant fee reports',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }

  getProcessingReportRuns(accountId: string, offset = 0, limit = DEFAULT_PAGE_LIMIT): void {
    const params: ListReportRunsRequestParams = {
      tilledAccount: accountId,
      reportRunRetrieveParams: {
        include_expired: false,
      },
      offset: offset,
      limit: limit,
      type: [ReportRun.TypeEnum.PROCESSING_SUMMARY_1],
    };
    this._internalService
      .internalListReportRuns(params)
      .pipe(
        tap((res) => this._processingReportRunsCount$.next(res.total)),
        map((res) => res.items),
        shareReplay(1),
      )
      .subscribe({
        next: (reportRuns) => {
          this._processingReportRuns$.next(reportRuns);
          this.processingReportRunHasPending = false;
          if (reportRuns.some((reportRun) => reportRun.status === ReportRun.StatusEnum.QUEUED)) {
            this.processingReportRunHasPending = true;
          }
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not load processing summary reports',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }

  getDisputeReportRuns(accountId: string, offset = 0, limit = DEFAULT_PAGE_LIMIT): void {
    const params: ListReportRunsRequestParams = {
      tilledAccount: accountId,
      reportRunRetrieveParams: {
        include_expired: false,
      },
      offset: offset,
      limit: limit,
      type: [ReportRun.TypeEnum.DISPUTES_SUMMARY_1],
    };
    this._internalService
      .internalListReportRuns(params)
      .pipe(
        tap((res) => this._disputeReportRunsCount$.next(res.total)),
        map((res) => res.items),
        shareReplay(1),
      )
      .subscribe({
        next: (reportRuns) => {
          this._disputeReportRuns$.next(reportRuns);
          this.disputeReportRunHasPending = false;
          if (reportRuns.some((reportRun) => reportRun.status === ReportRun.StatusEnum.QUEUED)) {
            this.disputeReportRunHasPending = true;
          }
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not load dispute summary reports',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }

  createReportRun(extraParams: ReportRunCreateParams, accountId: string): void {
    const createParams: CreateReportRunRequestParams = {
      tilledAccount: accountId,
      reportRunCreateParams: extraParams,
    };
    const createReportResponse$ = this._reportRunsService.createReportRun(createParams);

    const retrieveParams: ListReportRunsRequestParams = {
      tilledAccount: accountId,
      reportRunRetrieveParams: {
        include_expired: true,
      },
      offset: 0,
      limit: 5,
      type: [extraParams.type],
    };

    createReportResponse$
      .pipe(
        tap(() => {
          // show success message
        }),
        switchMap((response) =>
          this._internalService.internalListReportRuns(retrieveParams).pipe(
            tap((res) => {
              if (extraParams.type === ReportRun.TypeEnum.PAYMENTS_SUMMARY_1) {
                this._paymentReportRunsCount$.next(res.total);
                this.paymentReportRunHasPending = false;
                for (const reportRun of res.items) {
                  if (reportRun.status === ReportRun.StatusEnum.QUEUED) {
                    this.paymentReportRunHasPending = true;
                    break;
                  }
                }
              } else if (extraParams.type === ReportRun.TypeEnum.PAYOUTS_SUMMARY_2) {
                this._payoutReportRunsCount$.next(res.total);
                this.payoutReportRunHasPending = false;
                for (const reportRun of res.items) {
                  if (reportRun.status === ReportRun.StatusEnum.QUEUED) {
                    this.payoutReportRunHasPending = true;
                    break;
                  }
                }
              } else if (extraParams.type === ReportRun.TypeEnum.FEES_SUMMARY_1) {
                this._feeReportRunsCount$.next(res.total);
                this.feeReportRunHasPending = false;
                for (const reportRun of res.items) {
                  if (reportRun.status === ReportRun.StatusEnum.QUEUED) {
                    this.feeReportRunHasPending = true;
                    break;
                  }
                }
              } else if (extraParams.type === ReportRun.TypeEnum.PROCESSING_SUMMARY_1) {
                this._processingReportRunsCount$.next(res.total);
                this.processingReportRunHasPending = false;
                for (const reportRun of res.items) {
                  if (reportRun.status === ReportRun.StatusEnum.QUEUED) {
                    this.processingReportRunHasPending = true;
                    break;
                  }
                }
              } else if (extraParams.type === ReportRun.TypeEnum.DISPUTES_SUMMARY_1) {
                this._disputeReportRunsCount$.next(res.total);
                this.disputeReportRunHasPending = false;
                for (const reportRun of res.items) {
                  if (reportRun.status === ReportRun.StatusEnum.QUEUED) {
                    this.disputeReportRunHasPending = true;
                    break;
                  }
                }
              }
            }),
            map((res) => res.items),
          ),
        ),
        shareReplay(1),
      )
      .subscribe({
        next: (reportRuns) => {
          const message: TilledAlert = {
            message: 'Report was added to the queue to be processed',
            title: 'Report added to queue',
            type: 'success',
            timer: 8000,
          };
          this._alertService.showAlert(message);
          if (extraParams.type === ReportRun.TypeEnum.PAYMENTS_SUMMARY_1) {
            this._paymentReportRuns$.next(reportRuns);
            this._paymentReportRunCreated$.next(true);
          } else if (extraParams.type === ReportRun.TypeEnum.PAYOUTS_SUMMARY_2) {
            this._payoutReportRuns$.next(reportRuns);
            this._payoutReportRunCreated$.next(true);
          } else if (extraParams.type === ReportRun.TypeEnum.FEES_SUMMARY_1) {
            this._feeReportRuns$.next(reportRuns);
            this._feeReportRunCreated$.next(true);
          } else if (extraParams.type === ReportRun.TypeEnum.PROCESSING_SUMMARY_1) {
            this._processingReportRuns$.next(reportRuns);
            this._processingReportRunCreated$.next(true);
          } else if (extraParams.type === ReportRun.TypeEnum.DISPUTES_SUMMARY_1) {
            this._disputeReportRuns$.next(reportRuns);
            this._disputeReportRunCreated$.next(true);
          }
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message:
              "Could not create report '" +
              createParams.reportRunCreateParams.report_name +
              "'. " +
              err?.error?.message,
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }
}
