import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import {
  FuseNavigationItem,
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseConfigService } from '@fuse/services/config';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { InitialData } from 'app/app.types';
import { AppUser } from 'app/core/data/auth-types';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { AlertService } from 'app/core/services/alert.service';
import { AuthService } from 'app/core/services/auth.service';
import { BrandingService } from 'app/core/services/branding.service';
import { DisputesAppService } from 'app/core/services/disputes.app.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Account } from '../../../../../../projects/tilled-api-client/src';
const demo = require('../../../../../assets/js/asset-load.js').demo;

const DISPUTE_BADGE_REFRESH_MILLIS = 1000 * 60 * 5; // 5 mins

@Component({
  selector: 'futuristic-layout',
  templateUrl: './futuristic.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FuturisticLayoutComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public navItems: FuseNavigationItem[];
  public isScreenSmall: boolean;
  public data: InitialData;
  public user: AppUser;
  public darkLogoUrl$: Observable<string>;
  public logoUrl$: Observable<string>;
  public isWhiteLabel$: Observable<boolean>;
  public whiteText: boolean = true;
  public account: Account;
  public alert$: Observable<TilledAlert>;
  public companyName: string = demo.partnerName;
  private disputeAlertCount: number = 0;
  public disputeBadgeRefreshIntervalId: NodeJS.Timeout;

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _fuseConfigService: FuseConfigService,
    private _authService: AuthService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _brandingService: BrandingService,
    private _alertService: AlertService,
    private _disputesService: DisputesAppService,
  ) {
    this.darkLogoUrl$ = this._brandingService.darkLogoUrl$;
    this.logoUrl$ = this._brandingService.logoUrl$;
    this.isWhiteLabel$ = this._brandingService.isWhileLabel$;
    this._brandingService.primaryColor$.subscribe((color) => {
      if (color) {
        this.whiteText = this._brandingService.determineWhiteTextColorFromBgColor(color);
      }
    });
    this.alert$ = this._alertService.newAlert$;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the resolved route data
    this._activatedRoute.data.pipe(takeUntil(this._unsubscribeAll)).subscribe((data: Data) => {
      this.data = data.initialData;
    });

    // Subscribe to the user service
    this._authService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: AppUser) => {
      this.user = user;
    });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    this._authService.getAccount().then((account) => {
      this.account = account;
      this.data.navigation.futuristic.forEach((parentNavItem) => {
        // If this is a merchant and the menu item is for partners only
        // then remove the nav item
        if (this.account.type === Account.TypeEnum.MERCHANT) {
          this.data.navigation.futuristic = this.data.navigation.futuristic.filter((item) => !item.isPartnerScope);
          // If there are child nav items for partners only, remove them as well
          if (parentNavItem.children) {
            parentNavItem.children.forEach(() => {
              parentNavItem.children = parentNavItem.children.filter((item) => !item.isPartnerScope);
            });
          }
        }

        if (this.account.type === Account.TypeEnum.PARTNER) {
          // If this is a partner and the menu item is for merchants only
          // then remove the nav item
          this.data.navigation.futuristic = this.data.navigation.futuristic.filter((item) => !item.isMerchantScope);
          // If there are child nav items for merchants only, remove them as well
          if (parentNavItem.children) {
            parentNavItem.children.forEach(() => {
              parentNavItem.children = parentNavItem.children.filter((item) => !item.isMerchantScope);
            });
          }
        }

        // Set the nav items for this user/account
        this.navItems = this.data.navigation.futuristic;
      });

      // If dispute nav item present, configure badge updates
      const disputeItemIndex = this.navItems.findIndex((n) => n.id === 'disputes');
      if (disputeItemIndex >= 0) {
        this._disputesService.disputesAlertCount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((count: number) => {
          if (count !== this.disputeAlertCount) {
            this.disputeAlertCount = count;
            clearInterval(this.disputeBadgeRefreshIntervalId);
            if (count === 0) {
              this.navItems[disputeItemIndex] = {
                ...this.navItems[disputeItemIndex],
                badge: {},
              };
            } else {
              this.navItems[disputeItemIndex] = {
                ...this.navItems[disputeItemIndex],
                badge: {
                  title: `${count > 99 ? '99+' : count}`,
                  classes: 'w-6 h-6 bg-primary text-tilled-primary rounded-full',
                },
              };
              this.disputeBadgeRefreshIntervalId = setInterval(
                () => this._disputesService.getDisputesAlertCount(this.account.id),
                DISPUTE_BADGE_REFRESH_MILLIS,
              );
            }
            this.navItems = [...this.navItems];
          }
        });
        this._disputesService.getDisputesAlertCount(this.account.id);
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    clearInterval(this.disputeBadgeRefreshIntervalId);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }
}
