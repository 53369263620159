import { Pipe, PipeTransform } from '@angular/core';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { Dispute, InternalDispute } from '../../../../projects/tilled-api-client/src';

const MILLIS_PER_DAY = 1000 * 60 * 60 * 24;

/**
 * Takes a InternalDispute.StatusEnum
 */
@Pipe({
  name: 'disputeStatus',
})
export class DisputeStatusPipe implements PipeTransform {
  /**
   *
   * @param dispute Dispute
   * @returns
   */
  transform(dispute: InternalDispute): TilledChipConfig {
    const chipConfig: TilledChipConfig = {
      color: ChipColorClass.OPAQUE_ORANGE,
      text: dispute.status.toUpperCase(),
      toolTip: '',
    };
    if (dispute.status === Dispute.StatusEnum.WARNING_NEEDS_RESPONSE) {
      // Not used in paysafe dispute workflow
      const daysToRespond = this.daysBetween(new Date(), new Date(dispute.closing_at));
      chipConfig.color = ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = `${daysToRespond} DAY${daysToRespond === 1 ? '' : 'S'} TO RESPOND TO INQUIRY`;
    } else if (dispute.status === Dispute.StatusEnum.WARNING_UNDER_REVIEW) {
      chipConfig.color = ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = 'INQUIRY UNDER REVIEW';
    } else if (dispute.status === Dispute.StatusEnum.WARNING_CLOSED) {
      chipConfig.color = ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = 'INQUIRY CLOSED';
    } else if (dispute.status === Dispute.StatusEnum.NEEDS_RESPONSE) {
      const daysToRespond = this.daysBetween(new Date(), new Date(dispute.closing_at));
      chipConfig.color = ChipColorClass.OPAQUE_RED;
      chipConfig.text = `${daysToRespond} DAY${daysToRespond === 1 ? '' : 'S'} TO RESPOND`;
    } else if (dispute.status === Dispute.StatusEnum.UNDER_REVIEW) {
      chipConfig.color = ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = 'UNDER REVIEW';
    } else if (dispute.status === Dispute.StatusEnum.CLOSED) {
      chipConfig.color = ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = 'DEADLINE MISSED';
    } else if (dispute.status === Dispute.StatusEnum.WON) {
      chipConfig.color = ChipColorClass.OPAQUE_GREEN;
      chipConfig.text = 'WON';
    } else if (dispute.status === Dispute.StatusEnum.LOST) {
      chipConfig.color = ChipColorClass.OPAQUE_ACCENT;
      chipConfig.text = 'LOST';
    }

    return chipConfig;
  }

  private daysBetween(start: Date, end: Date) {
    const diffMillis = end.getTime() - start.getTime();
    const diffDays = Math.floor(diffMillis / MILLIS_PER_DAY);
    return diffDays;
  }
}
