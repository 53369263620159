/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WebhookEndpoint { 
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * The list of events to enable for this endpoint. You may specify `[\'*\']` to enable all events, except those that require explicit selection.
     */
    enabled_events: Array<WebhookEndpoint.EnabledEventsEnum>;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The id of the associated account.
     */
    account_id: string;
    /**
     * The URL of the webhook endpoint.
     */
    url: string;
    /**
     * An optional description of what the webhook is used for.
     */
    description?: string;
    /**
     * The endpoint\'s secret, used to generate webhook signatures. Only returned at creation.
     */
    secret?: string;
}
export namespace WebhookEndpoint {
    export type EnabledEventsEnum = '*' | 'account.updated' | 'charge.captured' | 'charge.expired' | 'charge.failed' | 'charge.succeeded' | 'charge.pending' | 'charge.refunded' | 'charge.refund.updated' | 'charge.updated' | 'customer.created' | 'customer.deleted' | 'customer.updated' | 'dispute.created' | 'dispute.updated' | 'payment_intent.canceled' | 'payment_intent.created' | 'payment_intent.payment_failed' | 'payment_intent.processing' | 'payment_intent.requires_action' | 'payment_intent.succeeded' | 'payment_intent.amount_capturable_updated' | 'payment_method.attached' | 'payment_method.detached' | 'payout.created' | 'payout.failed' | 'payout.paid' | 'payout.updated' | 'platform_fee.created' | 'platform_fee.refunded' | 'subscription.created' | 'subscription.canceled' | 'subscription.updated' | 'report_run.succeeded' | 'report_run.failed' | 'outbound_transfer.pending' | 'outbound_transfer.failed' | 'outbound_transfer.canceled' | 'outbound_transfer.succeeded';
    export const EnabledEventsEnum = {
        STAR: '*' as EnabledEventsEnum,
        ACCOUNT_UPDATED: 'account.updated' as EnabledEventsEnum,
        CHARGE_CAPTURED: 'charge.captured' as EnabledEventsEnum,
        CHARGE_EXPIRED: 'charge.expired' as EnabledEventsEnum,
        CHARGE_FAILED: 'charge.failed' as EnabledEventsEnum,
        CHARGE_SUCCEEDED: 'charge.succeeded' as EnabledEventsEnum,
        CHARGE_PENDING: 'charge.pending' as EnabledEventsEnum,
        CHARGE_REFUNDED: 'charge.refunded' as EnabledEventsEnum,
        CHARGE_REFUND_UPDATED: 'charge.refund.updated' as EnabledEventsEnum,
        CHARGE_UPDATED: 'charge.updated' as EnabledEventsEnum,
        CUSTOMER_CREATED: 'customer.created' as EnabledEventsEnum,
        CUSTOMER_DELETED: 'customer.deleted' as EnabledEventsEnum,
        CUSTOMER_UPDATED: 'customer.updated' as EnabledEventsEnum,
        DISPUTE_CREATED: 'dispute.created' as EnabledEventsEnum,
        DISPUTE_UPDATED: 'dispute.updated' as EnabledEventsEnum,
        PAYMENT_INTENT_CANCELED: 'payment_intent.canceled' as EnabledEventsEnum,
        PAYMENT_INTENT_CREATED: 'payment_intent.created' as EnabledEventsEnum,
        PAYMENT_INTENT_PAYMENT_FAILED: 'payment_intent.payment_failed' as EnabledEventsEnum,
        PAYMENT_INTENT_PROCESSING: 'payment_intent.processing' as EnabledEventsEnum,
        PAYMENT_INTENT_REQUIRES_ACTION: 'payment_intent.requires_action' as EnabledEventsEnum,
        PAYMENT_INTENT_SUCCEEDED: 'payment_intent.succeeded' as EnabledEventsEnum,
        PAYMENT_INTENT_AMOUNT_CAPTURABLE_UPDATED: 'payment_intent.amount_capturable_updated' as EnabledEventsEnum,
        PAYMENT_METHOD_ATTACHED: 'payment_method.attached' as EnabledEventsEnum,
        PAYMENT_METHOD_DETACHED: 'payment_method.detached' as EnabledEventsEnum,
        PAYOUT_CREATED: 'payout.created' as EnabledEventsEnum,
        PAYOUT_FAILED: 'payout.failed' as EnabledEventsEnum,
        PAYOUT_PAID: 'payout.paid' as EnabledEventsEnum,
        PAYOUT_UPDATED: 'payout.updated' as EnabledEventsEnum,
        PLATFORM_FEE_CREATED: 'platform_fee.created' as EnabledEventsEnum,
        PLATFORM_FEE_REFUNDED: 'platform_fee.refunded' as EnabledEventsEnum,
        SUBSCRIPTION_CREATED: 'subscription.created' as EnabledEventsEnum,
        SUBSCRIPTION_CANCELED: 'subscription.canceled' as EnabledEventsEnum,
        SUBSCRIPTION_UPDATED: 'subscription.updated' as EnabledEventsEnum,
        REPORT_RUN_SUCCEEDED: 'report_run.succeeded' as EnabledEventsEnum,
        REPORT_RUN_FAILED: 'report_run.failed' as EnabledEventsEnum,
        OUTBOUND_TRANSFER_PENDING: 'outbound_transfer.pending' as EnabledEventsEnum,
        OUTBOUND_TRANSFER_FAILED: 'outbound_transfer.failed' as EnabledEventsEnum,
        OUTBOUND_TRANSFER_CANCELED: 'outbound_transfer.canceled' as EnabledEventsEnum,
        OUTBOUND_TRANSFER_SUCCEEDED: 'outbound_transfer.succeeded' as EnabledEventsEnum
    };
}


