<tilled-table
  [data]="(disputesViewModel$ | async)!"
  [getPageCallback]="getDisputes"
  [dataLength]="disputesCount$ | async"
  data-test-id="disputes-table"
  [noDataMainText]="'No disputes exist'"
  [noDataSecondaryText]="'This is a good thing, congrats!'"
  [getClickCallback]="rowClickedCallback"
  [hideColumns]="hideColumns"
  [startingPageIndex]="pageIndex"
  [startingPageLimit]="pageSize"
></tilled-table>
