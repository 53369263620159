/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OutboundTransfer { 
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. Individual keys can be unset by posting an empty value (i.e. `\'\'` or `null`) to them. All keys can be unset by posting an empty value (i.e. `{}` or `null`) to `metadata`.
     */
    metadata?: { [key: string]: string; };
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Populated when `status` is `canceled`, this is the time at which the Outbound Transfer was canceled.
     */
    canceled_at?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The ID of the account for this outbound transfer.
     */
    account_id: string;
    /**
     * Amount of the outbound transfer, in currency minor units.
     */
    amount: number;
    /**
     * The status of the outbound transfer.
     */
    status: OutboundTransfer.StatusEnum;
    /**
     * The ID of destination [payment method](#tag/PaymentMethods) to send the outbound transfer to.
     */
    destination_payment_method_id: string;
    /**
     * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase.
     */
    currency: OutboundTransfer.CurrencyEnum;
    /**
     * Message to user further explaining the reason for the outbound transfer failure.
     */
    failure_message?: string;
    /**
     * Error code explaining reason for outbound transfer failure.
     */
    failure_code?: OutboundTransfer.FailureCodeEnum;
}
export namespace OutboundTransfer {
    export type StatusEnum = 'canceled' | 'failed' | 'pending' | 'succeeded';
    export const StatusEnum = {
        CANCELED: 'canceled' as StatusEnum,
        FAILED: 'failed' as StatusEnum,
        PENDING: 'pending' as StatusEnum,
        SUCCEEDED: 'succeeded' as StatusEnum
    };
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type FailureCodeEnum = 'account_closed' | 'account_frozen' | 'account_number_invalid' | 'authorization_revoked' | 'bank_account_blocked' | 'fraudulent' | 'insufficient_funds' | 'invalid_field' | 'limit_exceeded' | 'no_account' | 'not_authorized' | 'not_permitted' | 'processing_error' | 'not_recognized' | 'risk_blocked' | 'stop_payment';
    export const FailureCodeEnum = {
        ACCOUNT_CLOSED: 'account_closed' as FailureCodeEnum,
        ACCOUNT_FROZEN: 'account_frozen' as FailureCodeEnum,
        ACCOUNT_NUMBER_INVALID: 'account_number_invalid' as FailureCodeEnum,
        AUTHORIZATION_REVOKED: 'authorization_revoked' as FailureCodeEnum,
        BANK_ACCOUNT_BLOCKED: 'bank_account_blocked' as FailureCodeEnum,
        FRAUDULENT: 'fraudulent' as FailureCodeEnum,
        INSUFFICIENT_FUNDS: 'insufficient_funds' as FailureCodeEnum,
        INVALID_FIELD: 'invalid_field' as FailureCodeEnum,
        LIMIT_EXCEEDED: 'limit_exceeded' as FailureCodeEnum,
        NO_ACCOUNT: 'no_account' as FailureCodeEnum,
        NOT_AUTHORIZED: 'not_authorized' as FailureCodeEnum,
        NOT_PERMITTED: 'not_permitted' as FailureCodeEnum,
        PROCESSING_ERROR: 'processing_error' as FailureCodeEnum,
        NOT_RECOGNIZED: 'not_recognized' as FailureCodeEnum,
        RISK_BLOCKED: 'risk_blocked' as FailureCodeEnum,
        STOP_PAYMENT: 'stop_payment' as FailureCodeEnum
    };
}


