import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { InternalDispute, InternalListDisputesRequestParams } from '@tilled-api-client';
import { ComponentBase } from 'app/core/componentBase';
import { DEFAULT_PAGE_LIMIT } from 'app/core/constants';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { DisputeStatusPipe } from 'app/core/pipes/dispute-status.pipe';
import { AuthService } from 'app/core/services/auth.service';
import { DisputesAppService } from 'app/core/services/disputes.app.service';
import { ActionListItem } from 'app/shared/action-list/action-list.model';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TilledChipConfig } from '../tilled-chip/tilled-chip.component';
import { Column } from '../tilled-table/decorators/column';

@Component({
  selector: 'app-dispute-list',
  templateUrl: './dispute-list.component.html',
  styleUrls: ['./dispute-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DisputeListComponent extends ComponentBase implements OnInit, OnChanges {
  @Input() accountId: string = null;
  @Input() queryMerchantId: string = null;
  @Input() createdAtGte: string = null;
  @Input() createdAtLte: string = null;
  @Input() cardholder: string = null;
  @Input() pageIndex: number = 0;
  @Input() pageSize: number = DEFAULT_PAGE_LIMIT;
  @Input() includeConnectedAccounts: boolean = false;
  @Input() statuses: InternalDispute.StatusEnum[] = null;

  public disputes$: Observable<InternalDispute[]>;
  public disputesViewModel$: Observable<DisputeViewModel[]>;
  public disputesCount$: Observable<number>;

  public isLoading = true;
  public sortInfo = null;
  public hideColumns: number[] = [];
  public isMerchant: boolean = false;

  constructor(
    private _authService: AuthService,
    private _disputesAppService: DisputesAppService,
    private _dateFormatPipe: DateFormatPipe,
    private _router: Router,
    private _disputeStatusPipe: DisputeStatusPipe,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.disputes$ = this._disputesAppService.disputes$;
    this.disputesCount$ = this._disputesAppService.disputesCount$;

    this.disputesViewModel$ = this.disputes$.pipe(map((disputes) => this.getViewModelsFromDisputes(disputes)));

    this._authService.isMerchantAccount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isMerchant) => {
      this.isMerchant = isMerchant;
      if (isMerchant && this.hideColumns.length === 0) {
        this.hideColumns = [1];
      }
    });

    this.getDisputes(this.pageSize, this.pageIndex);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let firstChange = true;

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        firstChange = firstChange && changes[propName].isFirstChange();
      }
    }

    if (!firstChange) {
      this.getDisputes(this.pageSize, this.pageIndex);
    }
  }

  rowClickedCallback = (data: DisputeViewModel, event?: MouseEvent): void => {
    const url = `/${data.account_id}/payments/${data.payment_intent_id}`;

    // Open a new window (Shift) or tab (Ctrl/Option)
    if (event?.shiftKey || event?.ctrlKey || event?.metaKey) {
      window.open(url, event?.shiftKey ? '_blank' : undefined);
    } else {
      this._router.navigate([url]);
    }
  };

  getDisputes = (size: number, index: number, sort?: string): void => {
    const params: InternalListDisputesRequestParams = {
      tilledAccount: this.queryMerchantId ?? this.accountId,
      includeConnectedAccounts: this.includeConnectedAccounts,
      createdAtGte: this.createdAtGte,
      createdAtLte: this.createdAtLte,
      cardholder: this.cardholder,
      status: this.statuses,
      offset: size * index,
      limit: size,
      sort: sort,
    };
    this._disputesAppService.getAllDisputes(params);
  };

  getViewModelsFromDisputes(disputes: InternalDispute[]): DisputeViewModel[] {
    const viewModels: DisputeViewModel[] = [];
    if (!disputes || disputes.length === 0) {
      const temp: DisputeViewModel = new DisputeViewModel();
      viewModels.push(temp);
      return viewModels;
    }

    for (const dispute of disputes) {
      const temp: DisputeViewModel = new DisputeViewModel();
      temp.id = dispute.id;
      temp.account_id = dispute.account_id;
      temp.customer_id = dispute.customer_id;
      temp.payment_intent_id = dispute.payment_intent_id;
      temp.created_at = this._dateFormatPipe.transform(dispute.created_at);
      temp.closing_at = this._dateFormatPipe.transform(dispute.closing_at);
      temp.merchant = dispute.merchant_name;
      temp.cardholder = dispute.cardholder || '-';
      temp.reason_description = dispute.reason_description;
      temp.chipConfig = this._disputeStatusPipe.transform(dispute);
      temp.currency = dispute.currency;
      temp.amount = dispute.amount;

      temp.action = temp.action.concat([
        {
          name: 'View payment details',
          callback: (): void => {
            this._router.navigate([`/${dispute.account_id}/payments/${dispute.payment_intent_id}`]);
          },
          disabled: false,
        },
      ]);
      if (this.isMerchant && dispute.customer_id) {
        temp.action = temp.action.concat([
          {
            name: 'View customer',
            callback: (): void => {
              this._router.navigate([`/customers/${dispute.customer_id}`]);
            },
            disabled: false,
          },
        ]);
      }

      viewModels.push(temp);
    }
    return viewModels;
  }
}

export class DisputeViewModel {
  // non display
  id: string;
  account_id: string;
  customer_id: string;
  payment_intent_id: string;
  currency: InternalDispute.CurrencyEnum;
  chipConfig: TilledChipConfig;

  @Column({
    order: 0,
    name: 'Dispute Date',
    canSort: true,
    styling: 'min-width:120px',
  })
  created_at: string;

  @Column({
    order: 1,
    name: 'Evidence Due',
    canSort: true,
    styling: 'min-width:120px',
  })
  closing_at: string;

  @Column({
    order: 2,
    name: 'Merchant',
    styling: 'min-width:200px; max-width:250px;',
  })
  merchant: string;

  @Column({
    order: 3,
    name: 'Cardholder',
    styling: 'min-width:120px',
  })
  cardholder: string;

  @Column({
    order: 4,
    name: 'Reason',
    styling: 'min-width:280px',
    canSort: true,
  })
  reason_description: string;

  @Column({
    order: 5,
    name: 'Status',
    isChip: true,
    canSort: true,
    styling: 'min-width:170px; max-width:180px;',
  })
  status: string;

  @Column({
    order: 6,
    name: 'Amount',
    isCurrency: true,
    canSort: true,
    styling: 'min-width:100px; max-width:120px;',
  })
  amount: number;

  @Column({
    order: 7,
    isActionList: true,
  })
  action: ActionListItem[] = [];
}
