<merchant-app-card
  [stepNumber]="!forConsole ? '5' : null"
  [title]="!forConsole ? 'Business Bank Account Details' : null"
  [description]="!forConsole ? 'Enter the bank account information where funds will be deposited and debited' : null"
  [backButton]="!forConsole"
  (backClick)="onBackClicked($event)"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="bank-account-step" class="w-full" [formGroup]="bankAccountForm">
    <!-- Bank Account Info Alert -mx-6 -mt-2 -->
    <div>
      <fuse-alert
        data-test-id="bankAccountAlert"
        [type]="'basic'"
        [appearance]="'outline'"
        [dismissible]="false"
        [dismissed]="false"
        [name]="'bankAccountAlertBox'"
      >
        <mat-icon class="icon-size-5" fuseAlertIcon [svgIcon]="'heroicons_solid:information-circle'"> </mat-icon>
        <tilled-heading-h6 fuseAlertTitle>Bank Account Requirements</tilled-heading-h6>
        <div class="flex flex-row">
          &bull;&nbsp;<tilled-paragraph-p3
            >Your bank account must be registered with the same legal name or DBA as your merchant
            account.</tilled-paragraph-p3
          >
        </div>
        <div class="flex flex-row">
          &bull;&nbsp;<tilled-paragraph-p3>
            For sole proprietorships, your bank account must be registered under the same Beneficial Owner as your
            merchant account.</tilled-paragraph-p3
          >
        </div>

        <div class="flex flex-row">
          &bull;&nbsp;<tilled-paragraph-p3
            >Your bank account must be able to receive both ACH credits and debits.</tilled-paragraph-p3
          >
        </div>
      </fuse-alert>
    </div>

    <div *ngIf="isLoading" class="m-44 flex flex-col items-center justify-center">
      <div *ngIf="falconProcessing" class="m-6">Preparing your data on our super secure servers.</div>
      <mat-spinner diameter="50"></mat-spinner>
    </div>

    <div [hidden]="isLoading">
      <div *ngIf="showManualUpload && !forConsole" class="flex flex-row items-center space-x-2 pt-6">
        <tilled-button
          bgColor="black"
          buttonText="Connect with Plaid instead"
          (buttonClick)="connectWithPlaidInstead()"
        ></tilled-button>
        <tilled-paragraph-p3 class="">What is Plaid?</tilled-paragraph-p3>
        <mat-icon
          class="text-black icon-size-5"
          [svgIcon]="'heroicons_solid:information-circle'"
          matTooltip="Plaid is a technology that securely connects your bank account to third-party services using bank-level 256-bit encryption. It's important to note that your credentials are never shared with the third-party app or service, including our own. This helps ensure that your personal and financial data is kept safe and secure."
        ></mat-icon>
      </div>
      <div *ngIf="!showBankAccountForm && !forConsole" class="flex flex-col items-center">
        <div class="flex flex-col items-center space-y-6 pb-12 pt-6">
          <img class="w-24" src="/assets/icons/piggy_bank_icon.svg" />
          <tilled-heading-h4 class="text">Bank Account Verification</tilled-heading-h4>
          <div class="flex flex-row items-center space-x-2">
            <tilled-paragraph-p3 class="">Connect you bank account with Plaid to receive deposits.</tilled-paragraph-p3>
            <mat-icon
              class="text-black icon-size-5"
              [svgIcon]="'heroicons_solid:information-circle'"
              matTooltip="Plaid is a technology that securely connects your bank account to third-party services using bank-level 256-bit encryption. It's important to note that your credentials are never shared with the third-party app or service, including our own. This helps ensure that your personal and financial data is kept safe and secure."
            ></mat-icon>
          </div>

          <div class="flex flex-row items-center space-x-2">
            <mat-icon class="text-black" [svgIcon]="'heroicons_solid:lock-closed'"> </mat-icon>
            <tilled-paragraph-p3>We secure your information with bank-level 256-bit encryption. </tilled-paragraph-p3>
          </div>

          <tilled-button buttonText="Connect Account" (buttonClick)="getLinkToken()"></tilled-button>
        </div>

        <button (click)="showManualVerification()">
          <tilled-paragraph-p3 [primaryHighlight]="true"> Connect manually with account numbers. </tilled-paragraph-p3>
        </button>
        <tilled-paragraph-p4
          >A Bank Letter or Voided Check is required for underwriting to manually verify the bank
          account.</tilled-paragraph-p4
        >
      </div>

      <div *ngIf="verifiedByPlaid" class="py-4">
        <tilled-paragraph-p2 bold="true"> This account has been verified.</tilled-paragraph-p2>
        <button (click)="deletePlaidVerifiedAccount()">
          <tilled-paragraph-p4 [primaryHighlight]="true">
            Connect manually with account numbers instead.
          </tilled-paragraph-p4>
        </button>
      </div>
      <!-- Bank Account Details -->
      <div *ngIf="showBankAccountForm" class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
        <!-- first row -->
        <div class="col-span-1 flex flex-col">
          <tilled-label-l1 [secondary]="true" class="pt-11"> Account Type </tilled-label-l1>
          <mat-form-field appearance="standard" class="-mt-4 w-full">
            <mat-select data-test-id="account-type" class="text-secondary text-p3" [formControlName]="'accountType'">
              <mat-option [value]="'checking'">Checking</mat-option>
              <mat-option [value]="'savings'">Savings</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-span-1 flex flex-col"></div>
        <!-- second row -->
        <div class="col-span-1 flex flex-col">
          <tilled-input
            data-test-id="account-holder"
            class="w-full"
            name="accountHolder"
            label="Account Holder or Business Name"
            tilledLabel="true"
          ></tilled-input>
        </div>
        <div class="col-span-1 flex flex-col">
          <tilled-input
            data-test-id="bank-name"
            class="w-full"
            name="bankName"
            label="Bank Name"
            tilledLabel="true"
          ></tilled-input>
        </div>
        <!-- third row -->
        <div class="col-span-1 flex flex-col">
          <tilled-input
            data-test-id="account-number"
            class="w-full"
            name="accountNumber"
            label="Account Number"
            tilledLabel="true"
            maxLength="20"
          ></tilled-input>
        </div>
        <div class="col-span-1 flex flex-col">
          <tilled-input
            data-test-id="routing-number"
            class="w-full"
            name="routingNumber"
            [label]="routingNumberLabel"
            tilledLabel="true"
            maxLength="9"
            errors="{{ bankAccountForm.get('routingNumber').hasError('invalidRoutingNumber') }}"
            errorMessage="{{ routingNumberLabel }} is invalid."
          ></tilled-input>
        </div>
      </div>
    </div>
    <div *ngIf="showManualUpload">
      <div class="flex flex-row items-center space-x-2">
        <tilled-paragraph-p2 bold="true"> Upload a Voided Check or Bank Letter </tilled-paragraph-p2>
        <mat-icon
          class="text-black icon-size-5"
          [svgIcon]="'heroicons_solid:information-circle'"
          matTooltipClass="tooltip-list"
          [matTooltip]="bankLetterTooltipText"
        ></mat-icon>
      </div>

      <app-document-upload
        class="-ml-1 pt-8"
        [fileCountLimit]="2"
        [isMerchantApp]="true"
        [showOverlay]="false"
        [purpose]="filePurpose"
        [allowedFileTypes]="fileTypes"
        [fileDescriptions]="fileDescriptions"
        [existingFiles$]="existingFiles$"
        (fileUploaded)="fileUploaded($event)"
        (fileDeleted)="fileDeleted($event)"
        (pendingFilesChange)="pendingFilesChange($event)"
      ></app-document-upload>
    </div>
  </form>
</merchant-app-card>
