import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, ReplaySubject, shareReplay, tap } from 'rxjs';
import {
  Dispute,
  InternalDispute,
  InternalService,
  ListDisputesRequestParams,
} from '../../../../projects/tilled-api-client/src';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class DisputesAppService {
  private _disputes$ = new ReplaySubject<InternalDispute[]>();
  private _disputeSubject = new BehaviorSubject<InternalDispute>(null);
  private _disputesCount$ = new ReplaySubject<number>();
  private _disputesAlertCount$ = new BehaviorSubject<number>(0);

  public dispute$: Observable<InternalDispute> = this._disputeSubject.asObservable();
  public disputes$: Observable<InternalDispute[]> = this._disputes$.asObservable();
  public disputesCount$: Observable<number> = this._disputesCount$.asObservable();
  public disputesAlertCount$: Observable<number> = this._disputesAlertCount$.asObservable();

  constructor(private _internalService: InternalService, private _alertService: AlertService) {}

  public getAllDisputes(params: ListDisputesRequestParams): void {
    this._internalService
      .internalListDisputes(params)
      .pipe(
        tap((result) => this._disputesCount$.next(result.total)),
        map((result) => result.items),
        shareReplay(1),
      )
      .subscribe({
        next: (disputes) => {
          this._disputes$.next(disputes);
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not load all disputes',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }

  public getDisputesAlertCount(accountId: string): void {
    const params: ListDisputesRequestParams = {
      tilledAccount: accountId,
      includeConnectedAccounts: true,
      status: [Dispute.StatusEnum.WARNING_NEEDS_RESPONSE, Dispute.StatusEnum.NEEDS_RESPONSE],
      limit: 1,
    };
    this._internalService
      .internalListDisputes(params)
      .pipe(map((result) => result.total))
      .subscribe({
        next: (total) => {
          this._disputesAlertCount$.next(total);
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not load dispute alert count',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }
}
